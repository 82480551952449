import React, { forwardRef, useEffect } from "react";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { TeliaIconProps } from "@telia/b2x-telia-icon/types/types";
import classnames from "classnames";

import styles from "./b2x-telia-tab.module.scss";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  "data-testid"?: string;
  tabId: string;
  active: boolean;
  icon?: TeliaIconProps["name"];
  svg?: string;
  name?: string;
  dataIndex: number;
  variant: string;
  isKeyPress: boolean;
};

const TeliaTabHeaderComponent = <T extends HTMLButtonElement>(
  {
    active,
    dataIndex,
    tabId,
    variant = "default",
    "data-testid": dataTestid,
    icon,
    name,
    isKeyPress,
    children,
    ...rest
  }: ButtonProps,
  ref: React.ForwardedRef<T>
) => {
  const classes = classnames({
    [styles["telia-tab-header"]]: true,
    [styles["telia-tab-header--selected"]]: active,
    [styles[`telia-tab-header--${variant}`]]: !!variant,
  });

  useEffect(() => {
    if (active && isKeyPress) {
      document.querySelector<HTMLButtonElement>(`button[id="${tabId}-header"`)?.focus();
    }
  }, [active, tabId, isKeyPress]);

  return (
    <button
      className={classes}
      role="tab"
      id={tabId + "-header"}
      aria-selected={active}
      aria-controls={tabId + "-content"}
      data-testid={dataTestid}
      tabIndex={active ? 0 : -1}
      data-index={dataIndex}
      type="button"
      ref={ref}
      {...rest}
    >
      <span className={styles["telia-tab-header__content"]}>
        {icon && (
          <div className={styles["telia-tab-header__icon"]}>
            <TeliaIcon name={icon} />
          </div>
        )}
        <span>
          <span className={styles["telia-tab-header__visible-title"]}>{children}</span>
          {/* The hidden title is used to set a width of the content for the hover/selected behaviour */}
          <span className={styles["telia-tab-header__hidden-title"]} aria-hidden>
            {name}
          </span>
        </span>
      </span>
    </button>
  );
};
export const TeliaTabHeader = forwardRef(TeliaTabHeaderComponent);
