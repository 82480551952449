import React, {
  Children,
  cloneElement,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { isTabContent } from "@telia/b2x-telia-tab-content";
import classnames from "classnames";

import styles from "./b2x-telia-tab.module.scss";
import { createTabChangeDetailEvent, TabProps, TabVariants } from "./b2x-telia-tab.utils";
import { TeliaTabHeader } from "./tab-header";
import { TeliaTabHeaderLink } from "./tab-header-link";
const rootClassName = "telia-tab";

const scrollToTarget = (target: HTMLElement, tabList?: HTMLDivElement | null) => {
  /**
   * scroll may not be available in test runner, so check existence before
   * proceeding. We can't use scrollIntoView() because Safari doesn't support
   * it fully.
   */
  if (
    typeof target?.getBoundingClientRect !== "function" ||
    typeof tabList?.scroll !== "function"
  ) {
    return;
  }

  const targetRect = target.getBoundingClientRect();
  const wrapperRect = tabList.getBoundingClientRect();
  const wrapperWidth = tabList.clientWidth;
  const wrapperOffset = parseInt(getComputedStyle(tabList).borderLeftWidth?.split("px")[0], 10);

  const offset = wrapperRect.left + (isNaN(wrapperOffset) ? 0 : wrapperOffset);

  let left;
  //Handle forward navigation
  if (targetRect.right > wrapperRect.right) {
    left = targetRect.left + tabList.scrollLeft;
    left = left + targetRect.width - wrapperWidth + wrapperWidth * 0.1;
    left = left - offset;
  }

  //Handle backwards navigation
  if (targetRect.left < wrapperRect.left) {
    left = targetRect.left + tabList.scrollLeft;
    left = left - wrapperWidth * 0.1;
    left = left - offset;
  }

  if (left !== undefined) {
    tabList.scroll({ left: left, behavior: "smooth" });
  }
};

export const TeliaTab = ({
  children,
  variant = TabVariants.default,
  fullWidth = false,
  background = false,
  onChange,
  "data-testid": dataTestid,
  headerAsLinkTag,
}: TabProps) => {
  useEffect(() => {
    obsoleteWarning(`b2x-${rootClassName}`, "components-tabs--docs");
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollClasses, setScrollClasses] = useState({});
  const [isKeyPress, setIsKeyPress] = useState(false);
  const tabContentChildren = Children.toArray(children).filter(isTabContent);
  const length = tabContentChildren.length;
  const tabList = useRef<HTMLDivElement | null>();
  const tabChildren = useRef<(HTMLButtonElement | HTMLAnchorElement)[]>(Array.from({ length }));

  const classNames = classnames(styles[rootClassName], {
    [styles[`telia-tab--${variant}`]]: !!variant,
    [styles[`telia-tab--full-width`]]: !!fullWidth,
  });

  const keyDownHandler: KeyboardEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    if (e.key === "ArrowRight") {
      setIsKeyPress(true);
      setActiveIndex((currentActiveIndex) => {
        const nextIndex =
          currentActiveIndex < tabContentChildren.length - 1 ? currentActiveIndex + 1 : 0;
        onChange?.(createTabChangeDetailEvent(tabContentChildren[nextIndex].props.tabId));
        return currentActiveIndex < tabContentChildren.length - 1 ? currentActiveIndex + 1 : 0;
      });
    }

    if (e.key === "ArrowLeft") {
      setIsKeyPress(true);
      setActiveIndex((currentActiveIndex) => {
        const nextIndex =
          currentActiveIndex > 0 ? currentActiveIndex - 1 : tabContentChildren.length - 1;
        onChange?.(createTabChangeDetailEvent(tabContentChildren[nextIndex].props.tabId));
        return nextIndex;
      });
    }
  };

  useEffect(() => {
    const onIntersection = (entries: IntersectionObserverEntry[]): void => {
      const allEntriesVisible =
        entries.every((entry) => entry.isIntersecting) &&
        entries.length === tabContentChildren.length;
      if (allEntriesVisible) {
        setScrollClasses({});
        return;
      }

      entries.forEach((entry) => {
        const entryIndex = Number(entry.target.getAttribute("data-index"));

        const isStartEntry = entryIndex === 0;
        const isLastEntry = entryIndex === tabContentChildren.length - 1;

        setScrollClasses((currentScrollClasses) => ({
          [styles["telia-tab__wrapper--scroll-start"]]: true,
          ...currentScrollClasses,
          ...(isStartEntry && {
            [styles["telia-tab__wrapper--scroll-end"]]: !entry.isIntersecting,
          }),
          ...(isLastEntry && {
            [styles["telia-tab__wrapper--scroll-start"]]: !entry.isIntersecting,
          }),
        }));
      });
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: [0.99],
      root: tabList.current,
    });

    tabChildren.current.forEach((el) => observer.observe(el));
  }, [tabContentChildren.length]);

  return (
    <div data-testid={dataTestid} className={classNames}>
      <div className={styles["telia-tab__container"]}>
        <div className={classnames({ [styles["telia-tab__wrapper"]]: true, ...scrollClasses })}>
          <div
            ref={(el) => {
              tabList.current = el;
            }}
            className={styles["telia-tab__list"]}
            role="tablist"
          >
            {Children.map(tabContentChildren, (child, index) => {
              const { icon, name, tabId, "data-testid": dataTestid, active, tabPath } = child.props;
              const selected = active ?? index === activeIndex;
              return headerAsLinkTag ? (
                <TeliaTabHeaderLink
                  active={selected}
                  aria-selected={selected}
                  data-testid={dataTestid}
                  dataIndex={index}
                  icon={icon}
                  name={name}
                  onClick={(event) => {
                    event.preventDefault();
                    onChange?.(createTabChangeDetailEvent(tabId));
                    setActiveIndex(index);
                    setIsKeyPress(false);
                  }}
                  onFocus={(e) => {
                    scrollToTarget(e.target as HTMLElement, tabList.current);
                  }}
                  onKeyDown={keyDownHandler}
                  ref={(el) => {
                    if (el) {
                      tabChildren.current[index] = el;
                    }
                  }}
                  tabId={tabId}
                  variant={variant}
                  isKeyPress={isKeyPress}
                  href={tabPath}
                >
                  {name}
                </TeliaTabHeaderLink>
              ) : (
                <TeliaTabHeader
                  active={selected}
                  aria-selected={selected}
                  data-testid={dataTestid}
                  dataIndex={index}
                  icon={icon}
                  name={name}
                  onClick={() => {
                    onChange?.(createTabChangeDetailEvent(tabId));
                    setActiveIndex(index);
                    setIsKeyPress(false);
                  }}
                  onFocus={(e) => {
                    scrollToTarget(e.target as HTMLElement, tabList.current);
                  }}
                  onKeyDown={keyDownHandler}
                  ref={(el) => {
                    if (el) {
                      tabChildren.current[index] = el;
                    }
                  }}
                  tabId={tabId}
                  variant={variant}
                  isKeyPress={isKeyPress}
                >
                  {name}
                </TeliaTabHeader>
              );
            })}
          </div>
        </div>
        <div
          className={classnames({
            [styles["telia-tab__content"]]: true,
            [styles["telia-tab__content--light-background"]]: background,
          })}
          data-testid={`${dataTestid}-content-container`}
        >
          <div
            data-testid={`${dataTestid}-content`}
            className={styles["telia-tab__container"]}
            role="presentation"
          >
            {Children.map(tabContentChildren, (child, index) => {
              const { active } = child?.props || {};
              const actualActive = active ?? index === activeIndex;
              const props = { ...child.props, active: actualActive };
              const cloned = cloneElement(child, props);

              return cloned;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export * from "./b2x-telia-tab.utils";
