import { ReactElement } from "react";
import { TeliaTabContent } from "@telia/b2x-telia-tab-content";

export type TabProps = {
  children: Array<ReactElement<typeof TeliaTabContent>> | ReactElement<typeof TeliaTabContent>;
  variant?: TabVariant;
  fullWidth?: boolean;
  background?: boolean;
  onChange?: (event: CustomEvent<TabChangeDetail>) => void;
  "data-testid"?: string;
  headerAsLinkTag?: boolean;
};

export const TabVariants = {
  light: "light",
  contained: "contained",
  containedOnNegative: "contained-on-negative",
  default: "default",
  inline: "inline",
  wide: "wide",
  lightWide: "light-wide",
} as const;

// Array form for Storybook
export const TabVariantsTypesArray = Object.values(TabVariants);
// Create a string literal union type like 'text' | 'email' | ...
// from the const. This allows for cleaner documentation
// in a non-typescript environment.
export type TabVariant = typeof TabVariants[keyof typeof TabVariants];

export const createTabChangeDetailEvent = (value: string) =>
  new CustomEvent<TabChangeDetail>("tabChangeDetail", { detail: { value } });

export type TabChangeDetail = { value: string };
